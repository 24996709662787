import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { FaAngleDown , FaCheck} from "react-icons/fa";
import clsx from 'clsx'

export default function EnviromentSelect(props) {

    const [selected, setSelected] = useState({})

    useEffect(() => {
      setSelected(props.selected);
    }, [props.selected])

    return (
      <div className={props.isHidden ? 'w-full h-full' : 'hidden'}>
        <label htmlFor="location" className='block text-sm font-normal leading-6 text-gray-400 m-1'>
          {props.caption}
        </label>
        <Listbox value={selected} onChange={props.onChange}>
          <ListboxButton
            className={clsx(
              'relative block w-full rounded-lg bg-gray-100 py-1.5 pr-8 pl-3 text-left text-sm/6 text-black',
              ''
            )}
          >
            {selected?.id > 0 ? <span>{selected.name}</span> : <span className='text-red-700'>No Data Selected</span>}
            <FaAngleDown
              className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-black"
              aria-hidden="true"
            />
          </ListboxButton>
          <Transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <ListboxOptions
              anchor="bottom"
              className="absolute z-10 mt-1 max-h-60 w-[var(--button-width)] overflow-auto rounded-xl border border-gray-300 bg-white p-1"
            >
              {props.items.map((item) => (
                <ListboxOption
                  key={item.id}
                  value={item}
                  className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-blue-100"
                >
                  <FaCheck className="invisible size-4 fill-lime-700 group-data-[selected]:visible" />
                  <div className="text-sm/6 text-black">{item.name}</div>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </Listbox>
      </div>
    )
}
