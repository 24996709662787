import LogInScreen from "./LogInScreen";
import SignOutScreen from "./SignOutscreen";
import React from "react";
import { Route, Routes } from "react-router-dom";

export default function Authentication() {
  return (
    <Routes>
      <Route path="/login" element={<LogInScreen />} />
      <Route path="/signOut" element={<SignOutScreen />} />
    </Routes>
  );
}
