//Enviroment DropDown
export const FRANCHISE_ENDPOINT = `/franchises`;
export const FRASHORT_ENDPOINT = (id) => `/franchises/${id}/franchisees`;
export const CLISHORT_ENDPOINT = (id) => `/resellers/${id}/clients`;

//Resellers Table
export const RESELLERS_ENDPOINT = `/franchises`;

//Calls Data Table
export const CALLDATA_ENDPOINT = `/calls/data`;
export const RESCALLDATA_ENDPOINT = (id) => `/resellers/${id}/calls/data`;
export const FRANCHISES_CALLDATA_ENDPOINT = (id) =>
  `/franchises/${id}/calls/data`;
export const CLICALLDATA_ENDPOINT = (id) => `/clients/${id}/calls/data`;

//Agent Data Table
export const CALLS_ENDPOINT = `/calls`;
export const RESCALL_ENDPOINT = (id) => `/resellers/${id}/calls`;
export const FRACALL_ENDPOINT = (id) => `/franchises/${id}/calls`;
export const CLICALL_ENDPOINT = (id) => `/clients/${id}/calls`;

//Users Table
export const USERS_ENDPOINT = `/users`;
export const RESUSERS_ENDPOINT = (id) => `/resellers/${id}/users`;
export const FRAUSERS_ENDPOINT = (id) => `/franchises/${id}/users`;
export const CLIUSERS_ENDPOINT = (id) => `/clients/${id}/users`;

//LogIn token
export const ACCESSTOKEN_ENDPOINT = "/auth/login";

//Receptionist Link
export const RECEPTIONIST_ENDPOINT = (id) => `/users/${id}/impersonate`;
