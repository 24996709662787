import {
  RESELLERS_ENDPOINT,
  FRANCHISES_CALLDATA_ENDPOINT,
} from "../Api/ReceptionistApiEndpoints";
import { get, post } from "../Api/ReceptionistApiHttpVerbs";
import { AccordionCardRow } from "../Common/AccordionCardRow";
import { Filters } from "../Common/FilterComponents/Filters";
import { FormLineFilter } from "../Common/FilterComponents/FormLineFilter";
import { useState, useEffect } from "react";

export default function ResellersScreen() {
  const [resellers, setResellers] = useState([]);
  const [avarageCalls, setAvarageCalls] = useState(50);

  useEffect(() => {
    getReselers();
  }, []);

  const getReselers = (filter) => {
    console.log("filter", filter);
    // var agentNameId = 0;

    // if (filter !== undefined) {
    //   agentNameId = filter.agent === undefined ? 0 : filter.agent;
    // }

    get(RESELLERS_ENDPOINT).then((response) => {
      setResellers(response.data);
    });
  };

  const getFilter = (filter) => {
    getReselers(filter);
    if (filter === undefined) {
      window.location.reload();
    }
  };

  const setAvarageCallsAmount = (filter) => {
    if (filter !== undefined) {
      setAvarageCalls(filter.avarageCalls);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gray-100 p-5 pb-16 pt-20">
      {/* Filtering */}
      <Filters
        showDateRange={true}
        getData={(filter) => getFilter(filter)}
        data={resellers}
        type="resellers"
      />

      {resellers.map((content) => (
        <AccordionCardRow content={content} avarageCalls={avarageCalls} />
      ))}

      {/* Filtering avagerage calls amount */}
      <FormLineFilter
        type="callsAvarage"
        onFilter={(filter) => setAvarageCallsAmount(filter)}
        onClose=""
      />
      <div className="flex max-w-52 flex-col rounded-lg border bg-white pl-5">
        <div className="text-red-500">0 = red</div>
        <div className="text-yellow-500">{avarageCalls} or less = yellow</div>
        <div className="text-green-500">{avarageCalls} or more = green</div>
      </div>
    </div>
  );
}
