import {
  CALLS_ENDPOINT,
  CLICALL_ENDPOINT,
  RESCALLDATA_ENDPOINT,
  FRANCHISES_CALLDATA_ENDPOINT,
} from "../Api/ReceptionistApiEndpoints";
import { post } from "../Api/ReceptionistApiHttpVerbs";
import { Filters } from "../Common/FilterComponents/Filters";
import { TableComponent } from "../Common/TableComponent";
import { getStartDateFilter, getEndDateFilter } from "../Core/ClientStorage";
import { getCompany, getFranchisee, getClient } from "../Core/ClientStorage";
import { getSelectedGodMode } from "../Core/ClientStorage";
import { useState } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

export default function AgentTimeScreen() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [AgentTime, setAgentTime] = useState([]);

  useEffect(() => {
    getAgentTime();
  }, []);

  const getAgentTime = (filter) => {
    var apiLink = `${CALLS_ENDPOINT}`;

    switch (getSelectedGodMode()) {
      case "NAN":
        apiLink = `${CALLS_ENDPOINT}`;
        break;
      case "COMPANY":
        apiLink = `${FRANCHISES_CALLDATA_ENDPOINT(getCompany())}`;
        break;
      case "FRANCHISEE":
        apiLink = `${RESCALLDATA_ENDPOINT(getFranchisee())}`;
        break;
      case "CLIENT":
        apiLink = `${CLICALL_ENDPOINT(getClient())}`;
        break;
      default:
        apiLink = `${CALLS_ENDPOINT}`;
    }

    var telephoneNumber = "";
    var lengthOfCallMax = 0;
    var lengthOfCallMin = 0;
    var answered = false;

    if (filter !== undefined) {
      console.log(filter);
      telephoneNumber =
        filter.telephoneNumber === undefined ? "" : filter.telephoneNumber;
      lengthOfCallMax =
        filter.lengthOfCallMax === undefined ? 0 : filter.lengthOfCallMax;
      lengthOfCallMin =
        filter.lengthOfCallMin === undefined ? 0 : filter.lengthOfCallMin;
      answered = filter.answered === undefined ? false : filter.answered;
    }

    post(apiLink, {
      startDate: getStartDateFilter(),
      endDate: getEndDateFilter(),
      telephoneNumber: telephoneNumber,
      lengthOfCallMax: lengthOfCallMax,
      lengthOfCallMin: lengthOfCallMin,
      answered: answered,
    })
      .then((response) => {
        setAgentTime(response.data);
      })
      .catch((error) => {
        setIsAuthenticated(error.response.status != "401");
      });
  };

  if (isAuthenticated === false) {
    return <Navigate to="/auth/signout" />;
  }

  const TableTittles = [
    {
      id: 0,
      tittle: "Reseller / Client",
      mobHide: false,
      hidden: getSelectedGodMode() === "CLIENT",
    },
    { id: 1, tittle: "Date - Time", mobHide: false, hidden: false },
    { id: 2, tittle: "Answered", mobHide: false, hidden: false },
    { id: 3, tittle: "Tel Num ", mobHide: false, hidden: false },
    { id: 4, tittle: "Agent Time", mobHide: false, hidden: false },
  ];

  return (
    <div className="min-h-screen w-full bg-gray-100 p-5 pb-16 pt-20">
      <Filters
        showDateRange={true}
        getData={(filter) => getAgentTime(filter)}
        data={AgentTime}
        type="agentTime"
      />

      {/* Table */}
      <TableComponent
        contentType={"agentTime"}
        tableTittles={TableTittles}
        contents={AgentTime}
      />
    </div>
  );
}
