import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { TbLoaderQuarter } from "react-icons/tb";

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

export function PieChart(props) {
  const [loading, setLoading] = useState(true);

  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        backgroundColor: props.backgroundColor,
        borderColor: props.borderColor,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: props.labelsDisplay,
        anchor: "center",
      },
      legend: {
        display: props.displaylabels,
        position: "top",
      },
      title: {
        display: props.title !== null,
        text: props.title,
        color: "#020617",
        font: {
          size: 16,
          weight: "normal",
          family: "sans-serif",
        },
      },
    },
  };

  return loading ? (
    <div className="">
      <Doughnut data={data} options={options} />
    </div>
  ) : (
    <div className="mx-1 flex min-w-72 justify-center">
      <TbLoaderQuarter className="h-32 w-32 animate-spin text-blue-200" />
    </div>
  );
}
