import { ExportButton } from "../../Common/ExportButton";
import { ShowDateRange } from "../../Common/ShowDateRange";
import { FilterPopUpDialog } from "./FilterPoUpDialog";
import { FilterPopUpButton } from "./FilterPopUpButton";
import { FilterSimpleDate } from "./FilterSimpleDate";
import { useState } from "react";

export function Filters(props) {
  const [dateSelected, setDateSelected] = useState("");
  const [fiterPopShow, setFiterPopShow] = useState(false);

  const onFilter = (filter) => {
    props.getData(filter);
    setFiterPopShow(false);
  };

  return (
    <div className="mb-4 flex flex-wrap items-center justify-center gap-3">
      <div>
        <ExportButton data={props.data} fileName={props.type} />
      </div>
      {props.showDateRange && (
        <>
          <div>
            <ShowDateRange />
            <div className="flex gap-1">
              <FilterSimpleDate
                onFilter={props.getData}
                onBorder={() => setDateSelected("D")}
                type="D"
                className={dateSelected === "D" && "border-blue-300"}
              />
              <FilterSimpleDate
                onFilter={props.getData}
                onBorder={() => setDateSelected("W")}
                type="W"
                className={dateSelected === "W" && "border-blue-300"}
              />
              <FilterSimpleDate
                onFilter={props.getData}
                onBorder={() => setDateSelected("M")}
                type="M"
                className={dateSelected === "M" && "border-blue-300"}
              />
              <FilterSimpleDate
                onFilter={props.getData}
                onBorder={() => setDateSelected("Y")}
                type="Y"
                className={dateSelected === "Y" && "border-blue-300"}
              />
            </div>
          </div>

          <div>
            <FilterPopUpButton setFiterPopShow={() => setFiterPopShow(true)} />
            <FilterPopUpDialog
              showDateRange={props.showDateRange}
              type={props.type}
              show={fiterPopShow}
              close={() => setFiterPopShow(false)}
              onFilter={(filter) => onFilter(filter)}
            />
          </div>
        </>
      )}
    </div>
  );
}
