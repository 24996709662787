import { DateDisplayTime } from "../../Common/DateDisplay";
import { CallLengthDisplay } from "../../Common/CallLengthDisplay";
import { ClassificationTag } from "../TagComponents/ClassificationTag";
import { MessageForTag } from "../TagComponents/MessageForTag";
import { RowInfoTag } from "../TagComponents/RowInfoTag";
import { ResellerClientTag } from "../TagComponents/ResellerClientTag";
import { getSelectedGodMode } from '../../Core/ClientStorage';

export function TableContentCallData(props) {

    const content = props.contents
    const tailwindStyling = {
      0: 'whitespace-nowrap px-4 py-2 text-sm',
    }

    return (
      <tr key={content.id} className="hover:bg-sky-50 w-16">
        {/* Resselers / Clients */}
        {getSelectedGodMode() === 'CLIENT'? <></> :
        <td className={`${tailwindStyling[0]}`}>
          <ResellerClientTag franchiseeName={content.franchiseeName} franchiseName={content.franchiseName} franchiseColour={content.franchiseColour} clientName={content.clientName}/>
        </td> }

        {/* Agent name */}
        <td className={`${tailwindStyling[0]} rounded-l-md`}>{content.agentName}</td>
  
        {/* Date \ Time */}
        <td className={`${tailwindStyling[0]} font-mono text-gray-500`}><DateDisplayTime date={content.timeOfCall} />
          <dl className="lg:hidden">
            <dd className="font-mono mb-1 mt-2"><CallLengthDisplay timeSec={content.callTime} /></dd>
          </dl>
        </td>
  
        {/* Message For */}
        <td className={`${tailwindStyling[0]}`}><MessageForTag type={content.callFor} /></td>
  
        {/* Result */}
        <td className={`${tailwindStyling[0]}`}><ClassificationTag type={content.callResult} /></td>
  
        {/* Length of Call */}
        <td className={`${tailwindStyling[0]} hidden lg:table-cell font-mono `}><CallLengthDisplay timeSec={content.callTime} /></td>
  
        {/* Callers Number */}
        <td className={`${tailwindStyling[0]} font-mono`}>{content.cli}</td>
  
        {/* Caller's Name */}
        <td className={`${tailwindStyling[0]}`}>
          <RowInfoTag name={content.callersName} tel={content.telephoneNumber}/>
        </td>
  
        {/* Company Name*/}
        <td className={`${tailwindStyling[0]} `}>{content.companyName}</td>
  
        {/* Message */}
        <td className={`whitespace-normal px-4 py-2 text-sm sw:min-w-96 rounded-r-md`}>
          <div className="border-dashed border-2 border-gray-300 p-2 w-72">{content.message}</div>
        </td>
      </tr>
    );
  }
  