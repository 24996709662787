import {
  getStartDateFilter,
  getEndDateFilter,
  setStartDateFilter,
  setEndDateFilter,
} from "../../Core/ClientStorage";
import { format } from "date-fns";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FaFilter } from "react-icons/fa6";

// theme css file

function formatDateDisplay(date, defaultText) {
  if (!date) return defaultText;
  return format(date, "yyyy-MM-dd");
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function FilterDateSelect(props) {
  const [state, setState] = useState([
    {
      startDate: new Date(getEndDateFilter()),
      endDate: new Date(getStartDateFilter()),
      key: "selection",
    },
  ]);
  const [show, setShow] = useState(false);

  const handleSelect = (item) => {
    setState(item);
  };

  const onClickDone = () => {
    setStartDateFilter(formatDateDisplay(state[0].startDate));
    setEndDateFilter(formatDateDisplay(state[0].endDate));
    props.onFilter();
    FilterHide();
  };

  const FilterHide = () => {
    setShow(false);
  };

  return (
    <>
      <div className="w-full overflow-x-auto">
        <div className="p-4">
          <DateRangePicker
            onChange={(item) => handleSelect([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="vertical"
          />
        </div>
        <div className="flex justify-center p-4 pb-4 md:justify-end">
          <button
            className="rounded-md border-2 border-gray-300 bg-white px-4 py-2 hover:border-blue-300"
            onClick={onClickDone}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}
