import { removeLocalData } from "../Core/ClientStorage";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    removeLocalData();
    navigateToLogin();
  }, []);

  const navigateToLogin = () => {
    navigate("/auth/login");
  };

  return (
    <div className="h-full bg-gray-100">
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            Logging you out...
          </div>
        </div>
      </div>
    </div>
  );
}
