import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export function VerticalBarChart(props) {
  const data = {
    labels: props.data[1],
    datasets: [
      {
        data: props.data[0],
        backgroundColor: props.backgroundColor,
        borderColor: props.borderColor,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    animation: false,
    responsive: true,
    plugins: {
      legend: {
        display: props.displaylabels,
        position: "top",
      },
      title: {
        display: props.title !== null,
        text: props.title,
        color: "#020617",
        font: {
          size: 16,
          weight: "normal",
          family: "sans-serif",
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
}
