import { FaUserAlt, FaPhoneAlt  } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export function RowInfoTag(props) {

    return(
        <>
        <dl>
            {props.name?.length > 0 &&<dd><div className="flex items-center"><FaUserAlt className="mx-2"/><p>{props.name}</p></div></dd>}
            {props.email?.length > 0 &&<dd><div className="flex items-center"><MdEmail className="mx-2"/>{props.email}</div></dd>}
            {props.tel?.length > 0 &&<dd><div className="flex items-center"><FaPhoneAlt className="mx-2"/>{props.tel}</div></dd>}
        </dl>
        </>
    )
}