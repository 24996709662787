import { DateDisplayTime } from "../DateDisplay";
import { CallLengthDisplay } from "../CallLengthDisplay";
import { AnsweredTag } from "../TagComponents/AnsweredTag";
import {ResellerClientTag} from "../TagComponents/ResellerClientTag";
import { getSelectedGodMode } from '../../Core/ClientStorage';

export function TableContentAgentTime(props) {

    const content = props.contents
    const tailwindStyling = {
      0: 'whitespace-nowrap px-4 py-2 text-sm',
    }
  
    return (
      <tr key={content.id} className="hover:bg-sky-50 overflow-x-auto w-16">
        {/* Resselers / Clients */}
        {getSelectedGodMode() === 'CLIENT'? <></> :
        <td className={`${tailwindStyling[0]}`}>
          <ResellerClientTag franchiseeName={content.franchiseeName} franchiseName={content.franchiseName} franchiseColour={content.franchiseColour} clientName={content.clientName}/>
        </td> }
        
        {/* Date \ Time */}
        <td className={`${tailwindStyling[0]} font-mono text-gray-500`}><DateDisplayTime date={content.timeOfCall} /></td>
  
        {/* Answered */}
        <td className={`${tailwindStyling[0]} `}><AnsweredTag type={content.answered} /></td>
  
        {/* Tel Num */}
        <td className={`${tailwindStyling[0]} font-mono`}>{content.cli}</td>
  
        {/* Agent Time */}
        <td className={`${tailwindStyling[0]} font-mono`}><CallLengthDisplay timeSec={content.talkTime} /></td>
      </tr>
    );
  }