export function AnsweredTag(props) {

    const colouroptions = {
        0: 'bg-lime-100 text-lime-800 border-lime-800',
        1: 'bg-orange-100 text-orange-700 border-orange-700',
    };

    var type = props.type
    var colour = colouroptions[5]
    var text = "No Data";

    if (type == true){
        colour = colouroptions[0]
        text = "Yes"
    } else if (type == false){
        colour = colouroptions[1]
        text = "No"
    }

    return (<div className= {`rounded-md border-solid border flex justify-center px-3 w-16 ${colour}`} > {text} </div>);
}