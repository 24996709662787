import { RECEPTIONIST_ENDPOINT } from "../../Api/ReceptionistApiEndpoints";
import { post } from "../../Api/ReceptionistApiHttpVerbs";
import { setShowAlert } from "../../Core/ClientStorage";
import { FaPaperPlane } from "react-icons/fa";

export function ReceplinistLinkBtn(props) {
  const RequestLogInData = () => {
    console.log(props.id);
    post(RECEPTIONIST_ENDPOINT(props.id), {})
      .then((response) => {
        setShowAlert(false);
        response.data && window.open(response.data);
      })
      .catch(() => {
        setShowAlert(true);
        window.location.reload();
      });
  };

  return (
    <td
      onClick={() => RequestLogInData()}
      className="my-4 flex items-center rounded-lg border-2 border-gray-200 p-2 hover:border-rose-300"
    >
      <FaPaperPlane className="mr-4 h-6 w-6" />
      <div>{props.content}</div>
    </td>
  );
}
