import React from "react";

export const Pagination = (props) => {
  let pages = [];
  let totalpages = Math.ceil(props.totalPosts / props.postsPerPage);

  for (let i = 1; i <= totalpages; i++) {
    if (i >= props.currentPage - 2 && i <= props.currentPage + 2) {
      pages.push(i);
    }
  }

  if (totalpages <= 1) {
    return;
  }

  return (
    <div className="flex flex-row gap-3 rounded-full bg-gray-100 px-4 py-2 sm:gap-6">
      {props.currentPage >= 4 && (
        <>
          <button
            onClick={() => props.setCurrentPage(1)}
            className="hidden sm:block"
          >
            1
          </button>
          <button
            onClick={() => props.setCurrentPage(props.currentPage - 1)}
            className="hidden sm:block"
          >
            ...
          </button>
        </>
      )}

      {pages.map((page, index) => {
        return (
          <button
            key={index}
            onClick={() => props.setCurrentPage(page)}
            className={
              page == props.currentPage
                ? "rounded-full bg-rose-500 px-2 text-white"
                : ""
            }
          >
            {page}
          </button>
        );
      })}

      {props.currentPage <= totalpages - 3 && (
        <>
          <button
            onClick={() => props.setCurrentPage(props.currentPage + 1)}
            className="hidden sm:block"
          >
            ...
          </button>
          <div className="block sm:hidden">.</div>
          <button onClick={() => props.setCurrentPage(totalpages)}>
            {totalpages}
          </button>
        </>
      )}
    </div>
  );
};
