import { setCompany, setCompanyName } from "../../Core/ClientStorage";
import { CompanyTypeTag } from "../TagComponents/CompanyTypeTag";
import { Link } from "react-router-dom";

export function TableContentResellers(props) {
  const content = props.contents;
  const tailwindStyling = {
    0: "whitespace-nowrap px-4 py-2 text-sm",
  };

  const applyCompany = (id, name) => {
    setCompany(id);
    setCompanyName(name);
    window.location.reload();
  };

  return (
    <tr key={content.id} className="w-16 overflow-x-auto hover:bg-sky-50">
      {/* Company Logo */}
      <td className={`${tailwindStyling[0]} `}>
        <img src={content.logo} alt="No Image" className="w-40"></img>
      </td>

      {/* Name */}
      <td className={`${tailwindStyling[0]} `}>
        <Link to={`./${content.code}`}>{content.name}</Link>
      </td>

      {/* Code */}
      <td className={`${tailwindStyling[0]} `}>{content.code}</td>

      {/* Company Type */}
      <td className={`${tailwindStyling[0]} `}>
        <CompanyTypeTag
          isFran={content.isFranchisor}
          isInt={content.isInternal}
        />
      </td>

      {/* Receptionist Link */}
      <td className={`${tailwindStyling[0]} font-mono`}>
        <a
          href={`https://${content.subDomain}.callcentre.services`}
          target="_blank"
        >
          https://<span>{content.subDomain}</span>.callcentre.services
        </a>
      </td>
    </tr>
  );
}
