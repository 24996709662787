import { FRANCHISES_CALLDATA_ENDPOINT } from "../Api/ReceptionistApiEndpoints";
import { post } from "../Api/ReceptionistApiHttpVerbs";
import { VerticalBarChart } from "../Common/Charts/VerticalBarChart";
import { CompanyTypeTag } from "../Common/TagComponents/CompanyTypeTag";
import { getStartDateFilter, getEndDateFilter } from "../Core/ClientStorage";
import { ca } from "date-fns/locale";
import { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Navigate } from "react-router-dom";

export function AccordionCardRow(props) {
  const [grapthOpen, setGrapthOpen] = useState(false);
  const [calls, setCalls] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const content = props.content;

  useEffect(() => {
    getEndpointData();
  }, []);

  const getEndpointData = () => {
    post(FRANCHISES_CALLDATA_ENDPOINT(content.id), {
      startDate: getStartDateFilter(),
      endDate: getEndDateFilter(),
    })
      .then((response) => {
        setCalls(response.data);
      })
      .catch((error) => {
        setIsAuthenticated(error.response.status != "401");
      });
  };

  const GrapthData = () => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      month: "short",
      day: "2-digit",
      year: "2-digit",
    };

    let times = calls.map((item) => item.callTime);
    let dates = calls.map((item) =>
      new Date(item.timeOfCall).toLocaleDateString("en-GB", options),
    );

    return [times, dates];
  };

  const GrapthState = () => {
    setGrapthOpen((prev) => !prev);
  };

  if (isAuthenticated === false) {
    return <Navigate to="/auth/signout" />;
  }

  const borderColour = () => {
    if (calls.length < 1) {
      return "border-red-500";
    } else if (calls.length <= props.avarageCalls) {
      return "border-yellow-500";
    } else if (calls.length > props.avarageCalls) {
      return "border-green-500";
    } else {
      return "border-blue-500";
    }
  };

  return (
    <div className="mb-4 flex flex-col items-center">
      <div
        className={`flex min-h-14 w-full items-center rounded-md border-l-8 ${borderColour()} bg-white`}
      >
        <div className="mx-4 my-5 flex w-full flex-col-reverse items-center gap-8 md:my-0 md:flex-row">
          <button className="w-4" onClick={() => GrapthState()}>
            <IoIosArrowDown className={calls.length < 1 && "hidden"} />
          </button>
          <div className="m-4 hidden w-auto md:block md:w-1/3">
            <img src={content.logo} alt="No Image" className="w-40"></img>
          </div>
          <div className="w-auto md:w-1/3">
            <a href={`https://${content.subDomain}.callcentre.services`}>
              {content.name}
            </a>
            <div className="text-gray-400">{content.code}</div>
          </div>
          <div className="w-auto md:w-1/3">
            <CompanyTypeTag
              isFran={content.isFranchisor}
              isInt={content.isInternal}
            />
          </div>
        </div>
      </div>
      <div
        className={
          grapthOpen && calls.length > 0
            ? `mt-1 max-h-[40rem] w-full rounded-md border-l-8 ${borderColour()} bg-white p-4`
            : "hidden"
        }
      >
        <div className="block text-red-500 sm:hidden">
          {" "}
          Put the device in a landscape mode or use a bigger screen
        </div>
        <div className="hidden sm:block">
          <div className="flex h-[28rem] w-full items-center justify-center rounded-lg bg-white p-4 hover:border-2 hover:border-blue-300 lg:h-[38rem]">
            <VerticalBarChart
              displaylabels={false}
              title="Calls answered per reseller (Agent Time)"
              data={GrapthData()}
              backgroundColor={["#fde8e7"]}
              borderColor={["#ee2c24"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
