import {
  CALLS_ENDPOINT,
  RESELLERS_ENDPOINT,
  CALLDATA_ENDPOINT,
} from "../Api/ReceptionistApiEndpoints";
import { get, post } from "../Api/ReceptionistApiHttpVerbs";
import { PieChart } from "../Common/Charts/PieChart";
import { VerticalBarChart } from "../Common/Charts/VerticalBarChart";
import { getStartDateFilter, getEndDateFilter } from "../Core/ClientStorage";
import { addDays, set } from "date-fns";
import { format } from "date-fns";
import { useState, useEffect } from "react";

export default function DashboardScreen() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [AgentTime, setAgentTime] = useState([]);
  const [resellers, setResellers] = useState([]);
  const [companys, setCompanys] = useState([]);
  const [calls, setCalls] = useState([]);

  function formatDateDisplay(data) {
    return format(data, "yyyy-MM-dd");
  }
  const nowDay = formatDateDisplay(addDays(new Date(), 0));
  const nowMonth = formatDateDisplay(addDays(new Date(), -90));

  useEffect(() => {
    getEndpointData();
  }, []);

  const getEndpointData = () => {
    post(CALLS_ENDPOINT, {
      startDate: getStartDateFilter(),
      endDate: getEndDateFilter(),
    })
      .then((response) => {
        setAgentTime(response.data);
      })
      .catch((error) => {
        setIsAuthenticated(error.response.status != "401");
      });

    get(RESELLERS_ENDPOINT)
      .then((response) => {
        setResellers(response.data);
        setCompanys(response.data.map((item) => item.name));
      })
      .catch((error) => {
        setIsAuthenticated(error.response.status != "401");
      });

    post(CALLDATA_ENDPOINT, {
      startDate: getStartDateFilter(),
      endDate: getEndDateFilter(),
    })
      .then((response) => {
        setCalls(response.data);
      })
      .catch((error) => {
        setIsAuthenticated(error.response.status != "401");
      });
  };

  const getAnsweredProcent = () => {
    let yesAnswered = 0;

    AgentTime.map((item) => item.answered && yesAnswered++);

    const yesProcent = Math.round((yesAnswered / AgentTime.length) * 100);

    return [yesProcent, 100 - yesProcent];
  };

  const getAnsweredPerReseller = () => {
    let CompanyYes = companys.map(() => 0);
    let CompanyNo = companys.map(() => 0);

    let lenghtOfComp = 0;
    companys.map(() => lenghtOfComp++);

    for (let i = 0; i < lenghtOfComp; i++) {
      AgentTime.map((item) =>
        item.franchiseName == companys[i]
          ? item.answered
            ? CompanyYes[i]++
            : CompanyNo[i]++
          : "",
      );
    }

    return CompanyYes;
  };

  const getCallLength = () => {
    const backgroundColor = {
      0: "#fde8e7",
      1: "#ebeff9",
      2: "#fde8e7",
      3: "#e6f2ff",
      4: "#e6f4ff",
    };
    const borderColor = {
      0: "#ee2c24",
      1: "#304e9f",
      2: "#ee2c24",
      3: "#007bff",
      4: "#00548f",
    };

    const options = {
      month: "short",
      day: "2-digit",
      year: "2-digit",
    };

    let times = calls.map((item) => item.callTime);
    let dates = calls.map((item) =>
      new Date(item.timeOfCall).toLocaleDateString("en-GB", options),
    );
    let bgCol = calls.map((item) =>
      item.franchiseName == "Action Coach"
        ? backgroundColor[0]
        : item.franchiseName == "247 Receptionist"
          ? backgroundColor[1]
          : item.franchiseName == "Kallkwik"
            ? backgroundColor[2]
            : item.franchiseName == "Mailboxes Etc"
              ? backgroundColor[3]
              : item.franchiseName == "Juno Telecoms"
                ? backgroundColor[0]
                : backgroundColor[0],
    );

    let borderCol = calls.map((item) =>
      item.franchiseName == "Action Coach"
        ? borderColor[0]
        : item.franchiseName == "247 Receptionist"
          ? borderColor[1]
          : item.franchiseName == "Kallkwik"
            ? borderColor[2]
            : item.franchiseName == "Mailboxes Etc"
              ? borderColor[3]
              : item.franchiseName == "Juno Telecoms"
                ? borderColor[0]
                : borderColor[0],
    );

    return [times, dates, bgCol, borderCol];
  };

  const getCallClassification = () => {
    let MessageTaken = 0;
    let Other = 0;
    let CallerDisconnected = 0;
    let TransferredInternally = 0;
    let WrongNumber = 0;
    let Unknown = 0;

    calls.map((item) =>
      item.callResult === "Message Taken"
        ? MessageTaken++
        : item.callResult === "Other"
          ? Other++
          : item.callResult === "Caller Disconnected"
            ? CallerDisconnected++
            : item.callResult === "Transferred Internally"
              ? TransferredInternally++
              : item.callResult === "Wrong Number"
                ? WrongNumber++
                : Unknown++,
    );

    return [
      MessageTaken,
      Other,
      CallerDisconnected,
      TransferredInternally,
      WrongNumber,
      Unknown,
    ];
  };

  if (isAuthenticated === false) {
    return <Navigate to="/auth/signout" />;
  }

  return (
    <div className="flex h-screen w-full flex-col bg-gray-100 p-5 pt-20">
      <div className="mb-3 flex w-full flex-col flex-wrap items-center justify-center lg:flex-row">
        {/* Calls answered */}
        <div className="mx-2 mb-2 flex h-96 w-full items-center justify-center rounded-lg bg-white p-4 hover:border-2 hover:border-blue-300 lg:w-auto">
          <PieChart
            title="Calls answered %"
            labels={["YES", "NO"]}
            displaylabels={false}
            labelsDisplay={true}
            data={getAnsweredProcent()}
            backgroundColor={["#ecfccb", "#ffe4e6"]}
            borderColor={["#4d7c0f", "#be123c"]}
          />
        </div>

        {/* Calls answered per reseller */}
        <div className="mx-2 mb-2 flex h-96 w-full items-center justify-center rounded-lg bg-white p-4 hover:border-2 hover:border-blue-300 lg:w-auto">
          <PieChart
            title="Calls answered per reseller (Agent Time)"
            labels={companys}
            data={getAnsweredPerReseller()}
            labelsDisplay={true}
            displaylabels={false}
            backgroundColor={[
              "#fde8e7",
              "#ebeff9",
              "#fde8e7",
              "#e6f2ff",
              "#e6f4ff",
            ]}
            borderColor={[
              "#ee2c24",
              "#304e9f",
              "#ee2c24",
              "#007bff",
              "#00548f",
            ]}
          />
        </div>

        {/* Calls Classification */}
        <div className="mx-2 mb-2 flex h-96 w-full items-center justify-center rounded-lg bg-white p-4 hover:border-2 hover:border-blue-300 lg:w-auto">
          <PieChart
            title="Calls Classification"
            labels={[
              "MessageTaken",
              "Other",
              "CallerDisconnected",
              "TransferredInternally",
              "WrongNumber",
              "Unknown",
            ]}
            data={getCallClassification()}
            labelsDisplay={false}
            displaylabels={false}
            backgroundColor={[
              "#cffafe",
              "#e0f2fe",
              "#dbeafe",
              "#e0e7ff",
              "#ede9fe",
              "#f3f4f6",
            ]}
            borderColor={[
              "#0e7490",
              "#0369a1",
              "#1d4ed8",
              "#4338ca",
              "#6d28d9",
              "#374151",
            ]}
          />
        </div>
      </div>
    </div>
  );
}
