import { FilterDateSelect } from "./FilterDateSelection";
import { FormLineFilter } from "./FormLineFilter";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment } from "react";
import { IoClose } from "react-icons/io5";

export function FilterPopUpDialog(props) {
  return (
    <Transition show={props.show}>
      <Dialog className="relative z-50" onClose={props.close}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <div className="fixed inset-0 bg-gray-700/10 bg-opacity-75 transition-opacity" />
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-screen-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white px-4 py-6 shadow-2xl sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                        Filter Selector
                      </DialogTitle>
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500"
                        onClick={props.close}
                      >
                        <IoClose className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    {/* <div className="my-4 flex w-full justify-center overflow-x-auto rounded-md border-2 border-yellow-400">
                      <FormLineFilter
                        type={props.type}
                        onFilter={(filter) => props.onFilter(filter)}
                        onClose={props.close}
                      />
                    </div> */}
                    {props.showDateRange && (
                      <div className="my-4 flex w-full justify-center overflow-x-auto rounded-md border-2 border-blue-300">
                        <FilterDateSelect onFilter={() => props.onFilter()} />
                      </div>
                    )}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
