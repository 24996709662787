import { DEMO, DEVELOPMENT, LIVE, TESTING } from "../Api/EnviromentKeys";
import { NAN, COMPANY, FRANCHISEE, CLIENT } from "../Api/EnviromentKeys";
import { ACCESSTOKEN_ENDPOINT } from "../Api/ReceptionistApiEndpoints";
import { post } from "../Api/ReceptionistApiHttpVerbs";
import {
  setAccessToken,
  setEnviroment,
  setStartDateFilter,
  setEndDateFilter,
  setShowAlert,
} from "../Core/ClientStorage";
import { setSelectedGodMode } from "../Core/ClientStorage";
import { format } from "date-fns";
import { useState } from "react";
import { MdError } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function formatDateDisplay(data) {
  return format(data, "yyyy-MM-dd");
}

export default function LogInScreen() {
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [errored, setErrored] = useState();
  const navigate = useNavigate();

  const onFormSubmit = (e) => {
    e.preventDefault();

    post(ACCESSTOKEN_ENDPOINT, {
      userName: name,
      password: pass,
    }).then(
      (result) => {
        if (!result.data.success) {
          setErrored(true);
        }

        setShowAlert(false);
        setAccessToken(result.data.token);
        setEnviroment(LIVE);
        setSelectedGodMode(NAN);
        setStartDateFilter(formatDateDisplay(new Date()));
        setEndDateFilter(formatDateDisplay(new Date()));
        navigate("/franchises");
      },
      (error) => {
        setErrored(true);
      },
    );
  };

  return (
    <div className="z-50 flex min-h-full w-full flex-col items-center bg-gray-100 p-5 pt-20">
      {/* Page Tittle*/}
      <div className="my-5 bg-gray-100 py-2">
        <h1 className="text-4xl font-medium">Log In</h1>
      </div>

      {/* Forms*/}
      <form className="space-y-6" onSubmit={onFormSubmit}>
        <div>
          <label
            htmlFor="email"
            className="mt-5 block text-sm font-medium leading-6 text-gray-900"
          >
            Username
          </label>
          <div className="mt-2">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="username"
            />
          </div>
          <label
            htmlFor="email"
            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
          >
            Password
          </label>
          <div className="mt-2">
            <input
              type="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="***"
            />
          </div>

          <div>
            <button
              type="submit"
              className="mt-10 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>
          </div>
        </div>
      </form>

      <div
        className={errored ? "m-4 flex rounded-md bg-red-100 p-4" : "hidden"}
      >
        <div className="flex-shrink-0">
          <MdError className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-red-800">
            Username or Password incorrect
          </p>
        </div>
      </div>
    </div>
  );
}
