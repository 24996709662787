import RouteGuard from "../Authentication/RouteGard";
import AgentTimeScreen from "../Screens/AgentTimeScreen";
import CallsScreen from "../Screens/CallsScreen";
import DashboardScreen from "../Screens/Dashboard";
import ResellersScreen from "../Screens/ResellersScreen";
import UsersScreen from "../Screens/UsersScreen";
import ChangeEnviroment from "./ChangeEnviroment/ChangeEnviroment";
import BreadCrumps from "./NavBar/BreadCrumps";
import NavBar from "./NavBar/NavBar";
import React from "react";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";

export default function AdminApplication() {
  const [showChangeEnviroment, setShowChangeEnviroment] = useState(false);

  return (
    <>
      <NavBar />
      <Routes>
        <Route
          path="/"
          element={
            <RouteGuard>
              <DashboardScreen />
            </RouteGuard>
          }
        />
        <Route
          path="/franchises"
          element={
            <RouteGuard>
              <ResellersScreen />
            </RouteGuard>
          }
        />
        <Route
          path="/callsdata"
          element={
            <RouteGuard>
              <CallsScreen />
            </RouteGuard>
          }
        />
        <Route
          path="/calls"
          element={
            <RouteGuard>
              <AgentTimeScreen />
            </RouteGuard>
          }
        />
        <Route
          path="/users"
          element={
            <RouteGuard>
              <UsersScreen />
            </RouteGuard>
          }
        />
      </Routes>
      <BreadCrumps
        onShowChangeEnviroment={() => setShowChangeEnviroment(true)}
      />
      <ChangeEnviroment
        show={showChangeEnviroment}
        close={() => setShowChangeEnviroment(false)}
      />
    </>
  );
}
