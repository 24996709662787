import { setStartDateFilter, setEndDateFilter } from "../../Core/ClientStorage";
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from "date-fns";

function formatDateDisplay(data) {
  return format(data, "yyyy-MM-dd");
}

export function FilterSimpleDate(props) {
  const selectData = () => {
    const currentDate = new Date();
    let start = currentDate;
    let end = currentDate;

    if (props.type === "W") {
      start = startOfWeek(currentDate, { weekStartsOn: 1 });
      end = endOfWeek(currentDate, { weekStartsOn: 1 });
    } else if (props.type === "M") {
      start = startOfMonth(currentDate, { weekStartsOn: 1 });
      end = endOfMonth(currentDate, { weekStartsOn: 1 });
    } else if (props.type === "Y") {
      start = startOfYear(currentDate, { weekStartsOn: 1 });
      end = endOfYear(currentDate, { weekStartsOn: 1 });
    }

    setStartDateFilter(formatDateDisplay(start));
    setEndDateFilter(formatDateDisplay(end));
    props.onBorder();
    props.onFilter();
  };

  return (
    <button
      onClick={(e) => selectData()}
      className={`w-auto rounded-md border-2 border-gray-300 bg-white px-2 hover:border-indigo-300 ${props.className}`}
    >
      {props.type}
    </button>
  );
}
