export function DateDisplay(props) {
  const date = new Date(props.date);

  return (
    <span className="text-sm text-gray-500">
      {date.toLocaleDateString("default", { day: "2-digit", month: "short" })}
    </span>
  );
}

export function DateDisplayTime(props) {
  const date = new Date(props.date);
  const options = {
    month: "short",
    day: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return <span>{date.toLocaleDateString("en-GB", options)}</span>;
}
