import { Pagination } from "../Common/Pagination";
import { TableContentAgentTime } from "../Common/TableContent/TableContentAgentTime";
import { TableContentCallData } from "../Common/TableContent/TableContentCallData";
import { TableContentResellers } from "../Common/TableContent/TableContentResellers";
import { TableContentUsers } from "../Common/TableContent/TableContentUsers";
import { useState } from "react";

export function TableComponent(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(15);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const contents = props.contents.slice(firstPostIndex, lastPostIndex);

  const tableTittles = props.tableTittles;
  const contentType = props.contentType;

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="">
      <div className="overflow-x-auto">
        <table className="mb-4 w-full table-auto">
          {/* Table Titles */}
          <thead className="border-b-4 border-gray-300 bg-white">
            <tr className="bg-white">
              {tableTittles.map((tableTittle) => (
                <th
                  scope="col"
                  key={tableTittle.id}
                  className={classNames(
                    tableTittle.mobHide
                      ? "hidden lg:table-cell"
                      : "text-blue-500",
                    "text-md whitespace-nowrap p-4 text-left font-normal text-gray-500",
                    tableTittle.hidden && "hidden",
                  )}
                >
                  {tableTittle.tittle}
                </th>
              ))}
            </tr>
          </thead>

          {/* Table Content */}
          <tbody className="divide-y-4 divide-gray-100 bg-white">
            {/* Table Content */}
            {contents.map((content) => {
              return (
                <TableContent content={content} contentType={contentType} />
              );
            })}

            {/* No Data */}
            {contents.length <= 0 && (
              <tr>
                <td colSpan={tableTittles.length}>
                  <h3 className="m-6 text-center text-sm font-medium text-red-300">
                    No Calls Data
                  </h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="sticky bottom-12 flex w-auto flex-col items-center">
        <Pagination
          totalPosts={props.contents.length}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
}
export function TableContent(props) {
  const content = props.content;
  const contentType = props.contentType;

  if (contentType == "callData") {
    return <TableContentCallData contents={content} />;
  } else if (contentType == "agentTime") {
    return <TableContentAgentTime contents={content} />;
  } else if (contentType == "resellers") {
    return <TableContentResellers contents={content} />;
  } else if (contentType == "users") {
    return <TableContentUsers contents={content} />;
  }
}
