import Authentication from "./Authentication/Authentication";
import AdminApplication from "./Core/AdminApplication";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/*" element={<Authentication />} />
        <Route path="/*" element={<AdminApplication />} />
      </Routes>
    </BrowserRouter>
  );
}
