import {
  CALLDATA_ENDPOINT,
  RESCALLDATA_ENDPOINT,
  FRANCHISES_CALLDATA_ENDPOINT,
  CLICALLDATA_ENDPOINT,
} from "../Api/ReceptionistApiEndpoints";
import { post } from "../Api/ReceptionistApiHttpVerbs";
import { Filters } from "../Common/FilterComponents/Filters";
import { TableComponent } from "../Common/TableComponent";
import { getStartDateFilter, getEndDateFilter } from "../Core/ClientStorage";
import { getCompany, getFranchisee, getClient } from "../Core/ClientStorage";
import { getSelectedGodMode } from "../Core/ClientStorage";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

export default function CallsScreen() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [calls, setCalls] = useState([]);

  useEffect(() => {
    getCalls();
  }, []);

  const getCalls = (filter) => {
    var apiLink = `${CALLDATA_ENDPOINT}`;

    switch (getSelectedGodMode()) {
      case "NAN":
        apiLink = `${CALLDATA_ENDPOINT}`;
        break;
      case "COMPANY":
        apiLink = `${FRANCHISES_CALLDATA_ENDPOINT(getCompany())}`;
        break;
      case "FRANCHISEE":
        apiLink = `${RESCALLDATA_ENDPOINT(getFranchisee())}`;
        break;
      case "CLIENT":
        apiLink = `${CLICALLDATA_ENDPOINT(getClient())}`;
        break;
      default:
        apiLink = `${CALLDATA_ENDPOINT}`;
    }

    var agentNameId = 0;
    var classificationId = 0;
    var messageForId = 0;
    var telephoneNumber = "";
    var lengthOfCallMax = 0;
    var lengthOfCallMin = 0;
    var companyName = "";
    var callersName = "";

    console.log(filter);

    if (filter !== undefined) {
      agentNameId = filter.agent === undefined ? 0 : filter.agent;
      classificationId =
        filter.classificationId === undefined ? 0 : filter.classificationId;
      messageForId =
        filter.messageForId === undefined ? 0 : filter.messageForId;
      telephoneNumber =
        filter.telephoneNumber === undefined ? "" : filter.telephoneNumber;
      lengthOfCallMax =
        filter.lengthOfCallMax === undefined ? 0 : filter.lengthOfCallMax;
      lengthOfCallMin =
        filter.lengthOfCallMin === undefined ? 0 : filter.lengthOfCallMin;
      companyName = filter.companyName === undefined ? "" : filter.companyName;
      callersName = filter.callersName === undefined ? "" : filter.callersName;
    }

    post(apiLink, {
      startDate: getStartDateFilter(),
      endDate: getEndDateFilter(),
      agentId: agentNameId,
      ClassificationId: classificationId,
      MessageForId: messageForId,
      TelephoneNumber: telephoneNumber,
      LengthOfCallMin: lengthOfCallMin,
      LengthOfCallMax: lengthOfCallMax,
    })
      .then((response) => {
        setCalls(response.data);
        setFilteredCalls(response.data);
      })
      .catch((error) => {
        setIsAuthenticated(error.response.status != "401");
      });
  };

  if (isAuthenticated === false) {
    return <Navigate to="/auth/signout" />;
  }

  const TableTittles = [
    {
      id: 0,
      tittle: "Reseller / Client",
      mobHide: false,
      hidden: getSelectedGodMode() === "CLIENT",
    },
    { id: 1, tittle: "Agent name", mobHide: false, hidden: false },
    { id: 2, tittle: "Date - Time", mobHide: false, hidden: false },
    { id: 3, tittle: "Message For", mobHide: false, hidden: false },
    { id: 4, tittle: "Classification", mobHide: false, hidden: false },
    { id: 5, tittle: "Length of Call", mobHide: true, hidden: false },
    { id: 6, tittle: "Num Called By", mobHide: false, hidden: false },
    { id: 7, tittle: "Caller Info", mobHide: false, hidden: false },
    { id: 8, tittle: "Company Name", mobHide: false, hidden: false },
    { id: 9, tittle: "Message", mobHide: false, hidden: false },
  ];

  return (
    <div className="min-h-screen w-full bg-gray-100 p-5 pb-16 pt-20">
      {/* Filtering */}
      <Filters
        showDateRange={true}
        getData={(filter) => getCalls(filter)}
        data={calls}
        type="calls"
      />

      {/* Table */}
      <TableComponent
        contentType={"callData"}
        tableTittles={TableTittles}
        contents={calls}
      />
    </div>
  );
}
