export function FilterToogle(props) {
  return (
    <label className="flex items-center gap-2">
      <input
        type="checkbox"
        name={props.name}
        checked={props.value || false}
        onChange={props.onChange}
      />
      {props.title}
    </label>
  );
}
