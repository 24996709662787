export function ClassificationTag(props) {

   const colouroptions = {
      0: 'bg-cyan-100 text-cyan-700 border-cyan-700',
      1: 'bg-sky-100 text-sky-700 border-sky-700',
      2: 'bg-blue-100 text-blue-700 border-blue-700',
      3: 'bg-indigo-100 text-indigo-700 border-indigo-700',
      4: 'bg-violet-100 text-violet-700 border-violet-700',
      5: 'bg-gray-100 text-gray-500 border-gray-500'
   };

   var type = props.type
   var colour = colouroptions[5]

   if (type == "Message Taken"){
      colour = colouroptions[0]
   } else if (type == "Other"){
      colour = colouroptions[1]
   } else if (type == "Caller Disconnected"){
      colour = colouroptions[2]
   } else if (type == "Transferred Internally"){
      colour = colouroptions[3]
   } else if (type == "Wrong Number"){
      colour = colouroptions[4]
   }

   return (<div className= {`rounded-md border-solid border flex justify-center px-3 ${colour}`} > {type} </div>);
}