import {
  USERS_ENDPOINT,
  CLIUSERS_ENDPOINT,
  FRAUSERS_ENDPOINT,
  RESUSERS_ENDPOINT,
} from "../Api/ReceptionistApiEndpoints";
import { get } from "../Api/ReceptionistApiHttpVerbs";
import { AllertPopUp } from "../Common/AllertPopUp";
import { Filters } from "../Common/FilterComponents/Filters";
import { TableComponent } from "../Common/TableComponent";
import { getSelectedGodMode } from "../Core/ClientStorage";
import { getStartDateFilter, getEndDateFilter } from "../Core/ClientStorage";
import { getCompany, getFranchisee, getClient } from "../Core/ClientStorage";
import { getShowAlert } from "../Core/ClientStorage";
import { useState, useEffect } from "react";

export default function UsersScreen() {
  const [users, setUsers] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    getUsers();
  }, []);

  const getRoutURLParam = () => {
    let show = getShowAlert();

    let urlNow = window.location.href;
    if (urlNow.match("login-fail=true")) {
      return true;
    } else if (show === "true") {
      console.log(show);
      console.log("Hi");
      return true;
    } else {
      return false;
    }
  };

  const getUsers = () => {
    var apiLink = `${USERS_ENDPOINT}`;

    switch (getSelectedGodMode()) {
      case "NAN":
        apiLink = `${USERS_ENDPOINT}`;
        break;
      case "COMPANY":
        apiLink = `${FRAUSERS_ENDPOINT(getCompany())}`;
        break;
      case "FRANCHISEE":
        apiLink = `${RESUSERS_ENDPOINT(getFranchisee())}`;
        break;
      case "CLIENT":
        apiLink = `${CLIUSERS_ENDPOINT(getClient())}`;
        break;
      default:
        apiLink = `${USERS_ENDPOINT}`;
    }

    get(apiLink)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        setIsAuthenticated(error.response.status != "401");
      });
  };

  if (isAuthenticated === false) {
    return <Navigate to="/auth/signout" />;
  }

  const TableTittles = [
    { id: 0, tittle: "Users Info", mobHide: false, hidden: false },
    {
      id: 1,
      tittle: "Reseller / Client",
      mobHide: false,
      hidden: getSelectedGodMode() === "CLIENT",
    },
    { id: 2, tittle: "Company Type", mobHide: false, hidden: false },
    { id: 3, tittle: "Receptionist Link", mobHide: false, hidden: false },
  ];

  return (
    <div className="min-h-screen w-full bg-gray-100 p-5 pb-16 pt-20">
      <AllertPopUp
        show={getRoutURLParam()}
        text={
          <p>
            Trying to log you in to receptionist, failed.
            <br />
            Please try again or scream for Phill :D
          </p>
        }
      />

      {/* Filtering */}
      <Filters
        showDateRange={false}
        getData={(filter) => getUsers(filter)}
        data={users}
        type="users"
      />

      {/* Table */}
      <TableComponent
        contentType={"users"}
        tableTittles={TableTittles}
        contents={users}
      />
    </div>
  );
}
