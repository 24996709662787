const ENVIROMENT_KEY = "enviroment";
const STARTDATEFILTER_KEY = "startDateFilter";
const ENDDATEFILTER_KEY = "endDateFilter";
const COMPANY_KEY = "companyID";
const COMPANYNAME_KEY = "companyName";
const FRANCHISEE_KEY = "franchiseeID";
const FRANCHISEENAME_KEY = "franchiseeName";
const CLIENT_KEY = "clientID";
const CLIENTNAME_KEY = "clientName";
const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const GODMODE_KEY = "selectedGodMode";
const ALERT_KEY = "ShowAlert";

export function removeLocalData() {
  removeAccessToken();
  removeRefreshToken();
  removeEnviroment();
  removeCompany();
  removeFranchisee();
  removeClient();
  removeDataTimeFilter();
  removeSelectedGodMode();
  removeShowAlert();
}

export function getShowAlert() {
  return localStorage.getItem(ALERT_KEY);
}
export function setShowAlert(token) {
  localStorage.setItem(ALERT_KEY, token);
}
export function removeShowAlert() {
  localStorage.removeItem(ALERT_KEY);
}

export function getStartDateFilter() {
  return localStorage.getItem(STARTDATEFILTER_KEY);
}
export function setStartDateFilter(token) {
  localStorage.setItem(STARTDATEFILTER_KEY, token);
}
export function getEndDateFilter() {
  return localStorage.getItem(ENDDATEFILTER_KEY);
}
export function setEndDateFilter(token) {
  localStorage.setItem(ENDDATEFILTER_KEY, token);
}
export function removeDataTimeFilter() {
  localStorage.removeItem(STARTDATEFILTER_KEY);
  localStorage.removeItem(ENDDATEFILTER_KEY);
}

export function getEnviroment() {
  return localStorage.getItem(ENVIROMENT_KEY);
}
export function setEnviroment(token) {
  localStorage.setItem(ENVIROMENT_KEY, token);
}
export function removeEnviroment() {
  localStorage.removeItem(ENVIROMENT_KEY);
}

export function getSelectedGodMode() {
  return sessionStorage.getItem(GODMODE_KEY);
}
export function setSelectedGodMode(token) {
  sessionStorage.setItem(GODMODE_KEY, token);
}
export function removeSelectedGodMode() {
  sessionStorage.removeItem(GODMODE_KEY);
}

export function getCompany() {
  return sessionStorage.getItem(COMPANY_KEY);
}
export function setCompany(token) {
  sessionStorage.setItem(COMPANY_KEY, token);
}
export function getCompanyName() {
  return sessionStorage.getItem(COMPANYNAME_KEY);
}
export function setCompanyName(token) {
  sessionStorage.setItem(COMPANYNAME_KEY, token);
}
export function removeCompany() {
  sessionStorage.removeItem(COMPANY_KEY);
  sessionStorage.removeItem(COMPANYNAME_KEY);
}

export function getFranchisee() {
  return sessionStorage.getItem(FRANCHISEE_KEY);
}
export function setFranchisee(token) {
  sessionStorage.setItem(FRANCHISEE_KEY, token);
}
export function getFranchiseeName() {
  return sessionStorage.getItem(FRANCHISEENAME_KEY);
}
export function setFranchiseeName(token) {
  sessionStorage.setItem(FRANCHISEENAME_KEY, token);
}
export function removeFranchisee() {
  sessionStorage.removeItem(FRANCHISEE_KEY);
  sessionStorage.removeItem(FRANCHISEENAME_KEY);
}

export function getClient() {
  return sessionStorage.getItem(CLIENT_KEY);
}
export function setClient(token) {
  sessionStorage.setItem(CLIENT_KEY, token);
}
export function getClientName() {
  return sessionStorage.getItem(CLIENTNAME_KEY);
}
export function setClientName(token) {
  sessionStorage.setItem(CLIENTNAME_KEY, token);
}
export function removeClient() {
  sessionStorage.removeItem(CLIENT_KEY);
  sessionStorage.removeItem(CLIENTNAME_KEY);
}

export function getTokens() {
  return {
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
  };
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}
export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}
export function removeAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}
export function setRefreshToken(token) {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
}
export function removeRefreshToken() {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}
