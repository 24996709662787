import { DEMO, DEVELOPMENT, LIVE, TESTING } from "./EnviromentKeys";
import { getEnviroment } from "../Core/ClientStorage";

export const createUrl = (path) => { 

    var currentEnviroment = getEnviroment();  

var dev = `${process.env.REACT_APP_API_URL}${path}`;

    switch (currentEnviroment) {
        case DEVELOPMENT:
            return dev;
        case TESTING:
        case DEMO:
            return `https://admin.api.demo.callcentre.services${path}`;
        case LIVE:
            return `https://admin.api.callcentre.services${path}`;
        default:
            return `https://admin.api.callcentre.services${path}`;
    }
}

