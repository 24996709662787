export function FilterSelect(props) {
  return (
    <label className="flex flex-col items-center">
      {props.title}
      <select
        className="min-w-52 rounded-md border-2 border-gray-300 bg-white p-2 outline-none focus:border-yellow-400"
        name={props.name}
        onChange={props.onChange}
      >
        {props.selectList.map((item) => (
          <option value={item.id}>{item.name}</option>
        ))}
      </select>
    </label>
  );
}
