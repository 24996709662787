import { getSelectedGodMode } from "../../Core/ClientStorage";
import { ReceplinistLinkBtn } from "../TagComponents/ReceplinistLinkBtn";
import { ResellerClientTag } from "../TagComponents/ResellerClientTag";
import { RowInfoTag } from "../TagComponents/RowInfoTag";
import { UserTypeTag } from "../TagComponents/UserTypeTag";

export function TableContentUsers(props) {
  const content = props.contents;
  const tailwindStyling = {
    0: "whitespace-nowrap px-4 py-2 text-sm",
  };

  return (
    <tr key={content.id} className="w-16 overflow-x-auto hover:bg-sky-50">
      {/* Users Info */}
      <td className={`${tailwindStyling[0]} `}>
        <RowInfoTag name={content.name} email={content.email} />
      </td>

      {/* Reseller / Client */}
      {getSelectedGodMode() === "CLIENT" ? (
        <></>
      ) : (
        <td className={`${tailwindStyling[0]}`}>
          <ResellerClientTag
            franchiseeName={content.franchiseeName}
            franchiseName={content.franchiseName}
            franchiseColour={content.franchiseColour}
            clientName={content.clientName}
          />
        </td>
      )}

      {/* Company Type */}
      <td className={`${tailwindStyling[0]} `}>
        <UserTypeTag
          type={content.userType}
          name={content.franchiseeName}
          isInt={content.isInternalSeller}
        />
      </td>

      {/* Receptionist Link */}
      <td className={`${tailwindStyling[0]} font-mono`}>
        <ReceplinistLinkBtn content={content.subDomain} id={content.id} />
      </td>
    </tr>
  );
}
