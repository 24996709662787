export function CompanyTypeTag(props) {
  const colouroptions = {
    0: "bg-violet-100 text-violet-800 border-violet-800",
    1: "bg-yellow-100 text-yellow-700 border-yellow-700",
    2: "bg-blue-100 text-blue-700 border-blue-700",

    3: "bg-red-100 text-red-700 border-red-700",
  };

  var colour = colouroptions[3];
  var text = "No Data";

  if (props.isFran == true) {
    colour = colouroptions[1];
    text = "Franchisor";
  } else if (props.isInt == true) {
    colour = colouroptions[2];
    text = "Totally247";
  } else if (props.isFran == false && props.isInt == false) {
    colour = colouroptions[0];
    text = "Independent";
  } else {
    colour = colouroptions[3];
    text = "Incorrect Data";
  }

  return (
    <div
      className={`flex w-40 justify-center rounded-md border border-solid px-3 ${colour}`}
    >
      {text}
    </div>
  );
}
