import { Disclosure, DisclosurePanel, DisclosureButton } from '@headlessui/react'
import Logo2 from "../../Assets/Images/dragonfly.png";
import Logo from "../../Assets/Images/T247.png";
import { Link, NavLink } from "react-router-dom";
import { getCompany, getFranchisee, getClient } from "../../Core/ClientStorage";
import { IoMenu, IoClose } from "react-icons/io5";
import { FaStore } from "react-icons/fa";
import { useEffect, useState } from 'react'
import { ImExit } from "react-icons/im";

const navigation = [
  { id:0 , name: 'Resellers', to: "/franchises"},
  { id:1 , name: 'Calls Data', to: "/callsdata"},
  { id:2 , name: 'Agent Time', to: "/calls"},
  { id:3 , name: 'Users', to: "/users"},
]

const currentCompany = getCompany();
const currentFranchisee = getFranchisee();
const currentClient = getClient();

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DesktopNavBar(props) {

  const tailwindStyling = {
    0: 'w-full flex items-center justify-between', //nav
    1: 'h-6 w-6 ', //icons
    2: 'h-10', //logo
    3: "whitespace-nowrap text-sm font-normal px-3 py-2 flex items-center",
  }

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          {/* Main NavBar */}
          <div className={`${tailwindStyling[0]} h-16 px-8`}>

            {/* Mobile menu button*/}
            <div className=" md:hidden flex items-center justify-between">
              <DisclosureButton className="">
                {open ? (
                  <IoClose className= "block h-6 w-6" aria-hidden="true" />
                ) : (
                  <IoMenu className= "block h-6 w-6" aria-hidden="true" />
                )}
              </DisclosureButton>
            </div>

            <div className="">
                <Link to="/">
                    <img className={`${tailwindStyling[2]}`} src={Logo2} alt="totally247" />
                </Link>
            </div>

            {/* Desk menu List*/}
            <div className="hidden md:block">
              <div className="flex">
              {navigation.map((item) => (
                <NavLink
                style={{ textDecoration: 'none' }}
                key={item.name}
                to={item.to}
                className={({isActive}) => {
                  return (
                    'whitespace-nowrap text-sm font-normal px-4 flex items-center' +
                    (!isActive
                      ? ' text-black'
                      : ' text-rose-500'
                    )
                  );
                }}>
                  {item.name}
                </NavLink>
              ))}
              </div>
            </div>

            {/* SignOut button*/}
            <div>
              <Link
                to= "/auth/signOut"
                className="text-black"
              >
                <ImExit className={`${tailwindStyling[1]}`} aria-hidden="true" />
              </Link>
            </div>

          </div>

          {/* Mobile menu list*/}
          <DisclosurePanel className="md:hidden flex flex-col">
            {navigation.map((item) => (
              <DisclosureButton>
                <NavLink
                style={{ textDecoration: 'none' }}
                key={item.name}
                to={item.to}
                className={({isActive}) => {
                  return (
                    'whitespace-nowrap text-sm font-normal px-4 flex items-center m-4' +
                    (!isActive
                      ? ' text-black'
                      : ' text-rose-500'
                    )
                  );
                }}>
                  {item.name}
                </NavLink>
              </DisclosureButton>
            ))}
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}