import { MdKeyboardArrowRight } from "react-icons/md";
import { BsFileEarmarkPerson } from "react-icons/bs";

import { getCompanyName , getFranchiseeName, getClientName } from '../ClientStorage';

export default function BreadCrumps(props) {

  const tailwindStyling = {
    0: 'flex items-center justify-center p-4',
    1: 'whitespace-nowrap m-2 min-w-4',
    2: 'whitespace-nowrap truncate hover:text-clip'
  }

  return (
    <button className={`${tailwindStyling[0]} z-50 w-full h-12 bg-gray-200 fixed bottom-0`}
        type="button"
        onClick={props.onShowChangeEnviroment}>
          <BsFileEarmarkPerson className={`${tailwindStyling[1]} scale-150`}/>
      {(getCompanyName() !== null) ? <MdKeyboardArrowRight className={`${tailwindStyling[1]}`}/> : <div></div>}
      <div className={`${tailwindStyling[2]}`} >{getCompanyName()}</div>

      {(getFranchiseeName() !== null)? <MdKeyboardArrowRight className={`${tailwindStyling[1]}`}/>: <div></div>}
      <div className={`${tailwindStyling[2]}`} >{getFranchiseeName()}</div>
      
      {(getClientName() !== null) ? <MdKeyboardArrowRight className={`${tailwindStyling[1]}`}/>: <div></div>}
      <div className={`${tailwindStyling[2]}`} >{getClientName()}</div>
    </button>
  );
}