import { get } from "../Api/ReceptionistApiHttpVerbs";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export default function RouteGuard(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(0);

  useEffect(() => {
    get("/auth/challenge")
      .then(
        () => true,
        () => false,
      )
      .then((result) => setIsAuthenticated(result ? 1 : 2));
  });

  if (isAuthenticated === 2) return <Navigate to="/auth/signout" />;

  return (
    <div>
      {isAuthenticated === 1 ? (
        <div>{props.children}</div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
