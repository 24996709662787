import { RiBuilding4Fill , RiBuildingFill} from "react-icons/ri";
import { HiOfficeBuilding } from "react-icons/hi";

export function ResellerClientTag(props) {
    return(
        <>
        <dl>
            {props.franchiseName?.length > 0 &&<dd ><div className="flex items-center"><RiBuilding4Fill className="mr-2"/><div style={{color: `${props.franchiseColour}`}}>{props.franchiseName}</div></div></dd>}
            {props.franchiseeName?.length > 0 &&<dd><div className="flex items-center text-gray-600"><RiBuildingFill className="mr-2"/>{props.franchiseeName}</div></dd>}
            {props.clientName?.length > 0 &&<dd><div className="flex items-center text-gray-400"><HiOfficeBuilding className="mr-2"/>{props.clientName}</div></dd>}
        </dl>
        </>
    )
}