import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import  EnviromentSelect  from "../ChangeEnviroment/EnviromentSelect"
import { setEnviroment } from '../ClientStorage';
import { DEMO, DEVELOPMENT, LIVE, TESTING } from '../../Api/EnviromentKeys';
import { NAN, COMPANY, FRANCHISEE, CLIENT } from '../../Api/EnviromentKeys';
import { getCompany, setCompany, setCompanyName, removeCompany } from '../ClientStorage';
import { getFranchisee, setFranchisee, setFranchiseeName, removeFranchisee } from '../ClientStorage';
import { getClient, setClient, setClientName, removeClient } from '../ClientStorage';
import { setSelectedGodMode } from '../ClientStorage';
import { FRANCHISE_ENDPOINT, FRASHORT_ENDPOINT, CLISHORT_ENDPOINT } from "../../Api/ReceptionistApiEndpoints";
import { get } from '../../Api/ReceptionistApiHttpVerbs';
import { IoClose } from "react-icons/io5";
import { Navigate } from 'react-router-dom';

export default function ChangeEnviroment(props) {

    const [dataResellers, setDataResellers] = useState([]);
    const [dataFranchisee, setDataFranchisee] = useState([]);
    const [dataClient, setDataClient] = useState([]);

    const [selectedCompany, setSelectedCompany] = useState({});
    const [selectedFranchisee, setSelectedFranchisee] = useState({});
    const [selectedClient, setSelectedClient] = useState({});

    const FranchiseeID = getFranchisee();
    const ClientID = getClient();
    const [isHiddenFranchisee, setisHiddenFranchisee] = useState(!(FranchiseeID === null));
    const [isHiddenClient, setisHiddenCliente] = useState(!(ClientID === null));

    const [isAuthenticated, setIsAuthenticated] = useState(true);

    useEffect(() => {

      if (!props.show)
        return;

      setSelectedCompany([null]);
      setSelectedClient([null]);
      setSelectedFranchisee([null]);

      get(FRANCHISE_ENDPOINT)
      .then((response) => {
        setDataResellers(response.data);
        const id = response.data.filter(x => x.id == getCompany());
        if (id.length > 0){ //if company selected
          
          let comp = id[0];

          setSelectedCompany(comp);

          if (comp.isFranchisor){ //if selected company is frenchisor
            get(`${FRASHORT_ENDPOINT(comp.id)}`)
            .then((response) => {
              setDataFranchisee(response.data)
              let id = response.data.filter(x => x.id == getFranchisee());
              if (id.length > 0){setSelectedFranchisee(id[0]);};
              setisHiddenFranchisee(true);

              const frenchID = getFranchisee()
              if (frenchID === null ){ //if frenchises is NOT selected
                setisHiddenCliente(false);

              } else { //if frenchises is selected
                get(`${CLISHORT_ENDPOINT(frenchID)}`)
                .then((response) => {
                  setDataClient(response.data)
                  let id = response.data.filter(x => x.id == getClient());
                  if (id.length > 0){setSelectedClient(id[0]);};
                  setisHiddenCliente(true);
                }).catch(error => {
                  setIsAuthenticated(error.response.status != "401");
                });
              }
            }).catch(error => {
              setIsAuthenticated(error.response.status != "401");
            });
          } else { //if selected company is NOT frenchisor
            get(`${CLISHORT_ENDPOINT(comp.id)}`)
            .then((response) => {
              setDataClient(response.data)
              let id = response.data.filter(x => x.id == getClient());
              if (id.length > 0){setSelectedClient(id[0]);};
              setisHiddenCliente(true);
              setisHiddenFranchisee(false);
            }).catch(error => {
              setIsAuthenticated(error.response.status != "401");
            });;
          };



        } else { //if company NOT selected
          setSelectedCompany([null]);
          setSelectedClient([null]);
          setSelectedFranchisee([null]);
          setisHiddenFranchisee(false);
          setisHiddenCliente(false);
        }
      }).catch(error => {
        setIsAuthenticated(error.response.status != "401");
      });
    }, [props.show]);

    const onResellerChange = (e) => {
      setSelectedCompany(e);
      setSelectedClient([null]);
      setSelectedFranchisee([null]);
      if (e.isFranchisor) {
        get(`${FRASHORT_ENDPOINT(e.id)}`).then((response) => {
          setDataFranchisee(response.data)
          setisHiddenFranchisee(true);
          setisHiddenCliente(false);
        });
      } else {
        get(`${CLISHORT_ENDPOINT(e.id)}`).then((response) => {
          setDataClient(response.data)
          setisHiddenCliente(true);
          setisHiddenFranchisee(false);
        })
      }
    };

    const onFranchiseeChange = (e) => {
      setSelectedFranchisee(e);
      setSelectedClient([null]);
      get(`${CLISHORT_ENDPOINT(e.id)}`).then((response) => {
        setDataClient(response.data)
        setisHiddenCliente(true);
      })
    };

    const onClientChange = (e) => {
      setSelectedClient(e);
    };

    const applyChangeRFC = (e) => {
      if((selectedCompany.id !== undefined) && (selectedCompany !== null)){
        setCompany(selectedCompany.id);
        setCompanyName(selectedCompany.name);
        setSelectedGodMode(COMPANY);
      } else {removeCompany();setSelectedGodMode(NAN); }

      if((selectedFranchisee.id !== undefined) && (selectedFranchisee !== null)){
        setFranchisee(selectedFranchisee.id);
        setFranchiseeName(selectedFranchisee.name);
        setSelectedGodMode(FRANCHISEE);
      } else {removeFranchisee();}

      if((selectedClient.id !== undefined) && (selectedClient !== null)){
        setClient(selectedClient.id);
        setClientName(selectedClient.name);
        setSelectedGodMode(CLIENT);
      } else{removeClient();}
      window.location.reload();
    };

    const deselectAllRFC = (e) => {
      removeCompany();
      removeFranchisee();
      removeClient();
      setSelectedGodMode(NAN);
      window.location.reload();
    };

    const removeResellerSelected = (e) => {
      setSelectedCompany([null]);
      setSelectedClient([null]);
      setSelectedFranchisee([null]);
      setisHiddenCliente(false);
      setisHiddenFranchisee(false);
    };

    const removeFranchiseeSelected = (e) => {
      setSelectedClient([null]);
      setSelectedFranchisee([null]);
      setisHiddenCliente(false);
    };

    const removeClientSelected = (e) => {
      setSelectedClient([null]);
    };

    const changeEnviromentlocal= (e) => {
      setEnviroment(e);
    }

    if (isAuthenticated === false){
      return <Navigate to="/auth/signout" />};

    return (
      <Transition show={props.show}>
        <Dialog className="relative z-50" onClose={props.close}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                          Account Selector :D
                        </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={props.close}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <IoClose  className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className='space-y-3 p-6 h-5/6'>
                        <div className='flex flex-row items-end'>
                          <EnviromentSelect caption="RESELLER" items={dataResellers} selected={selectedCompany} isHidden={true} onChange={(e) => onResellerChange(e)}/>
                          <button onClick={removeResellerSelected}><IoClose className='scale-150 text-red-600 mb-2.5 ml-3'/></button>
                        </div>

                        <div className={isHiddenFranchisee ? 'hidden' : 'animate-pulse'}>
                          <label htmlFor="location" className='block text-sm font-normal leading-6 text-gray-400 m-1'>FRANCHISEE</label>
                          <div className='bg-gray-300 w-auto h-10 rounded-lg mr-7 '></div>
                        </div>
                        <div className={isHiddenFranchisee ? 'flex flex-row items-end' : 'hidden'}>
                          <EnviromentSelect caption="FRANCHISEE" items={dataFranchisee} selected={selectedFranchisee} isHidden={isHiddenFranchisee} onChange={(e) => onFranchiseeChange(e)}/>
                          <button onClick={removeFranchiseeSelected}><IoClose className='scale-150 text-red-600 mb-2.5 ml-3'/></button>
                        </div>
                        
                        <div className={isHiddenClient ? 'hidden' : 'animate-pulse'}>
                          <label htmlFor="location" className='block text-sm font-normal leading-6 text-gray-400 m-1'>CLIENT</label>
                          <div className='bg-gray-300 w-auto h-10 rounded-lg mr-7 '></div>
                        </div>
                        <div className={isHiddenClient ? 'flex flex-row items-end' : 'hidden'}>
                          <EnviromentSelect caption="CLIENT" items={dataClient} selected={selectedClient} isHidden={isHiddenClient} onChange={(e) => onClientChange(e)}/>
                          <button onClick={removeClientSelected}><IoClose className='scale-150 text-red-600 mb-2.5 ml-3'/></button>
                        </div>
                        <div className='flex flex-row items-end py-5'>
                          <button type="submit" onClick={applyChangeRFC} className="w-full flex justify-center py-2 px-4 mr-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-800 hover:bg-indigo-700">Apply</button>
                          <button type="submit" onClick={deselectAllRFC} className="w-full flex justify-center py-2 px-4 ml-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-red-800 hover:bg-red-700">Clear Selection</button>
                        </div>
                      </div>
                      <div className='pb-2 pt-20 px-6 flex felx-col justify-between w-full'>
                        <button type="submit" onClick={(e) => changeEnviromentlocal(DEVELOPMENT)} className="px-4 py-2 mr-2 rounded-lg text-sm font-medium text-white bg-blue-400 hover:bg-blue-500">{DEVELOPMENT}</button>
                        <button type="submit" onClick={(e) => changeEnviromentlocal(DEMO)} className="px-4 py-2  rounded-lg text-sm font-medium text-white bg-blue-400 hover:bg-blue-500">{DEMO}</button>
                        <button type="submit" onClick={(e) => changeEnviromentlocal(LIVE)} className="px-4 py-2 ml-2 rounded-lg text-sm font-medium text-white bg-blue-400 hover:bg-blue-500">{LIVE}</button>
                        </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
      </Transition>
    )
}
