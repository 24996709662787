import ChangeEnviroment from "../ChangeEnviroment/ChangeEnviroment";
import DesktopNavBar from "./DesktopNavBar";
import { Disclosure } from "@headlessui/react";
import { useState } from "react";

export default function NavBar() {
  const [showChangeEnviroment, setShowChangeEnviroment] = useState(false);

  return (
    <>
      <Disclosure as="nav" className="fixed top-0 z-30 w-full bg-white">
        {({ open }) => (
          <>
            <DesktopNavBar
              onShowChangePasswordModal={() => setShowChangePasswordModal(true)}
              onShowChangeEnviroment={() => setShowChangeEnviroment(true)}
            />
          </>
        )}
      </Disclosure>
    </>
  );
}
