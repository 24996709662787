import { FilterSelect } from "./FilterSelect";
import { FilterString } from "./FilterString";
import { FilterSubmit } from "./FilterSubmit";
import { FilterToogle } from "./FilterToogle";
import { useState } from "react";

export function FormLineFilter(props) {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleNumberChange = (event) => {
    const name = event.target.name;
    const value = Number(event.target.value);
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleCheck = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const onClickDone = (event) => {
    event.preventDefault();
    props.onFilter(inputs);
  };
  const randomNames = [
    { id: 0, name: "" },
    { id: 1, name: "Emma Johnson" },
    { id: 2, name: "Liam Brown" },
    { id: 3, name: "Ava Williams" },
    { id: 4, name: "Noah Jones" },
    { id: 5, name: "Isabella Garcia" },
    { id: 6, name: "Lucas Martinez" },
    { id: 7, name: "Mia Rodriguez" },
    { id: 8, name: "Ethan Hernandez" },
    { id: 9, name: "Sophia Lee" },
    { id: 10, name: "James King" },
    { id: 11, name: "Charlotte Lopez" },
    { id: 12, name: "Benjamin Wilson" },
    { id: 13, name: "Amelia Clark" },
    { id: 14, name: "Henry Lewis" },
    { id: 15, name: "Harper Walker" },
    { id: 16, name: "Alexander Hall" },
    { id: 17, name: "Evelyn Young" },
    { id: 18, name: "Daniel Allen" },
    { id: 19, name: "Abigail Scott" },
  ];

  if (props.type === "calls") {
    return (
      <div className="w-full">
        <form onSubmit={onClickDone} className="p-4">
          <div className="flex flex-wrap justify-center gap-2 pb-4 md:justify-start">
            <FilterSelect
              title="Agent Name"
              name="agentNameId"
              selectList={randomNames || 0}
              onChange={handleNumberChange}
            />
            <FilterSelect
              title="Classification"
              name="classificationId"
              selectList={randomNames || 0}
              onChange={handleNumberChange}
            />
            <FilterSelect
              title="Message For"
              name="messageForId"
              selectList={randomNames || 0}
              onChange={handleNumberChange}
            />
            <FilterString
              type="number"
              title="Telephone Number"
              name="telephoneNumber"
              value={inputs.telephoneNumber || null}
              onChange={handleChange}
            />
            <FilterString
              type="number"
              title="Length Of Call Min"
              name="lengthOfCallMin"
              value={inputs.lengthOfCallMin || null}
              onChange={handleChange}
            />
            <FilterString
              type="number"
              title="Length Of Call Max"
              name="lengthOfCallMax"
              value={inputs.lengthOfCallMax || null}
              onChange={handleChange}
            />
            <FilterString
              type="text"
              title="Callers Name"
              name="callersName"
              value={inputs.callersName || null}
              onChange={handleChange}
            />
            <FilterString
              type="text"
              title="Company Name"
              name="companyName"
              value={inputs.companyName || null}
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-center pb-4 md:justify-end md:pb-0">
            <FilterSubmit />
          </div>
        </form>
      </div>
    );
  } else if (props.type === "agentTime") {
    return (
      <div className="w-full">
        <form onSubmit={onClickDone} className="p-4">
          <div className="flex flex-wrap justify-center gap-2 pb-4 md:justify-start">
            <FilterString
              type="number"
              title="Telephone Number"
              name="telephoneNumber"
              value={inputs.telephoneNumber || null}
              onChange={handleChange}
            />
            <FilterString
              type="number"
              title="Length Of Call Min"
              name="lengthOfCallMin"
              value={inputs.lengthOfCallMin || null}
              onChange={handleChange}
            />
            <FilterString
              type="number"
              title="Length Of Call Max"
              name="lengthOfCallMax"
              value={inputs.lengthOfCallMax || null}
              onChange={handleChange}
            />
            <FilterToogle
              title="Answered"
              name="answered"
              value={inputs.answered || ""}
              onChange={handleCheck}
            />
          </div>
          <div className="flex justify-center pb-4 md:justify-end md:pb-0">
            <FilterSubmit />
          </div>
        </form>
      </div>
    );
  } else if (props.type === "resellers") {
    return (
      <div className="w-full">
        <form onSubmit={onClickDone} className="p-4">
          <div className="flex flex-wrap justify-center gap-2 pb-4 md:justify-start">
            <FilterSelect
              title="Comp Type"
              name="compType"
              selectList={randomNames || 0}
              onChange={handleNumberChange}
            />
          </div>
          <div className="flex justify-center pb-4 md:justify-end md:pb-0">
            <FilterSubmit />
          </div>
        </form>
      </div>
    );
  } else if (props.type === "users") {
    return (
      <div className="w-full">
        <form onSubmit={onClickDone} className="p-4">
          <div className="flex flex-wrap justify-center gap-2 pb-4 md:justify-start">
            <FilterString
              type="text"
              title="User Name"
              name="userName"
              value={inputs.userName || null}
              onChange={handleChange}
            />
            <FilterString
              type="text"
              title="User Email"
              name="userEmail"
              value={inputs.userEmail || null}
              onChange={handleChange}
            />
            <FilterSelect
              title="Comp Type"
              name="compType"
              selectList={randomNames || 0}
              onChange={handleNumberChange}
            />
          </div>
          <div className="flex justify-center pb-4 md:justify-end md:pb-0">
            <FilterSubmit />
          </div>
        </form>
      </div>
    );
  } else if (props.type === "callsAvarage") {
    return (
      <div className="">
        <form onSubmit={onClickDone} className="py-2">
          <input
            type="number"
            title="Avarage Calls"
            name="avarageCalls"
            value={inputs.avarageCalls || null}
            onChange={handleChange}
            className={`w-20 rounded-md border-2 border-gray-300 bg-white p-2 outline-none focus:border-yellow-400`}
          />
          <button className="rounded-md border-2 border-gray-300 bg-white px-4 py-2 hover:border-yellow-400">
            <input type="submit" value="Avarage Calls" />
          </button>
        </form>
      </div>
    );
  }

  return <div className="text-red-">Undefined page type</div>;
}
