import { getStartDateFilter, getEndDateFilter } from "../Core/ClientStorage";
import { format } from "date-fns";

function formatDateDisplay(data) {
  return format(data, "dd MMM");
}

export function ShowDateRange() {
  return (
    <div className="flex flex-row items-center justify-center gap-2">
      <p className="text-sm text-gray-500">
        {formatDateDisplay(getStartDateFilter())} -
      </p>
      <p className="text-sm text-gray-500">
        {formatDateDisplay(getEndDateFilter())}
      </p>
    </div>
  );
}
