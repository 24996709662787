import { getStartDateFilter, getEndDateFilter } from "../Core/ClientStorage";
import {
  getCompanyName,
  getFranchiseeName,
  getClientName,
} from "../Core/ClientStorage";
import * as FileSaver from "file-saver";
import React from "react";
import { FaFileExcel } from "react-icons/fa6";
import * as XLSX from "xlsx";

export function ExportButton(props) {
  const selectFileName = () => {
    const fileExtension = ".xlsx";
    const dataSelected = getStartDateFilter() + " _ " + getEndDateFilter();
    const accountSelected =
      (getCompanyName() !== null ? getCompanyName() : "") +
      (getFranchiseeName() !== null ? "_" + getFranchiseeName() : "") +
      (getClientName() !== null ? "_" + getClientName() : "");

    return (
      props.fileName +
      " " +
      dataSelected +
      " " +
      accountSelected +
      fileExtension
    );
  };

  const fixCallData = () => {
    let dataList = [];
    for (let i = 1; i < props.data.length; i++) {
      dataList.push({
        ID: props.data[i].id,
        "FRANCHISE NAME": props.data[i].franchiseName,
        "FRANCHISEE NAME": props.data[i].franchiseeName,
        "CLIENT NAME": props.data[i].clientName,
        "AGENT NAME": props.data[i].agentName,
        "DATE / TIME": props.data[i].timeOfCall,
        "MESSAGE FOR": props.data[i].callFor,
        CLASSIFICATION: props.data[i].callResult,
        "LENGHT OF CALL": props.data[i].callTime,
        "NUM CALLED BY": props.data[i].cli,
        "CALLER INFO": props.data[i].callersName,
        "TELEPHONE NUMBER": props.data[i].telephoneNumber,
        "COMPANY NAME": props.data[i].companyName,
        MESSAGE: props.data[i].message,
      });
    }
    return dataList;
  };

  const fixAgentTime = () => {
    let dataList = [];
    for (let i = 1; i < props.data.length; i++) {
      dataList.push({
        ID: props.data[i].id,
        "FRANCHISE NAME": props.data[i].franchiseName,
        "FRANCHISEE NAME": props.data[i].franchiseeName,
        "CLIENT NAME": props.data[i].clientName,
        "DATE / TIME": props.data[i].timeOfCall,
        ANSWERED: props.data[i].answered,
        "TEL NUN": props.data[i].cli,
        "AGENT TIME": props.data[i].talkTime,
      });
    }
    return dataList;
  };

  const exportToExcelFile = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.json_to_sheet(
      props.fileName === "Calls Data" ? fixCallData() : fixAgentTime(),
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, selectFileName());
  };

  return (
    <button
      onClick={(e) => exportToExcelFile()}
      className="rounded-md border-2 border-gray-300 bg-white p-4 hover:border-green-300"
    >
      <FaFileExcel />
    </button>
  );
}
