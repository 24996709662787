export function CallLengthDisplay(props) {

    var sec = props.timeSec;
    var min = 0;

    if (sec < 60){ //sec
        return (<span>{sec} Sec</span>);

    } else if (sec < 3600){ //min sec

        min = Math.floor(sec / 60);
        sec = sec - (min * 60)

        return (<span>{min} Min {sec} Sec</span>);

    } else { //hours min sec

        const hours = Math.floor(sec / 3600);
        min = Math.floor(sec / 60 - (hours * 60));
        sec = sec - ((min * 60) + (hours * 3600))

        return (<span> {hours} Hours {min} Min {sec} Sec</span>);

    }

}