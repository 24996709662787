import { getAccessToken, getEnviroment } from "../Core/ClientStorage";
import { createUrl } from "./ReceptionistApiUrl";
import axios from "axios";

export const get = (path) => axios.get(createUrl(path), getRequestConfig());

export const post = (path, data = null) => {
  const url = createUrl(path);

  return data
    ? axios.post(url, data, getRequestConfig())
    : axios.post(url, getRequestConfig());
};

function getRequestConfig() {
  return {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "X-enviroment": getEnviroment(),
    },
  };
}
