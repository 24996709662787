import { FaFilter } from "react-icons/fa6";

export function FilterPopUpButton(props) {
  return (
    <button
      onClick={props.setFiterPopShow}
      className={`rounded-md border-2 border-gray-300 bg-white p-4 hover:border-yellow-300`}
    >
      <FaFilter />
    </button>
  );
}
