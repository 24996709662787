export function FilterString(props) {
  return (
    <label className="flex flex-col items-center">
      {props.title}
      <input
        type={props.type || "text"}
        name={props.name}
        value={props.value || ""}
        onChange={props.onChange}
        className={`min-w-52 rounded-md border-2 border-gray-300 bg-white p-2 outline-none focus:border-yellow-400`}
      />
    </label>
  );
}
