export function UserTypeTag(props) {

    const colouroptions = {
        0: 'bg-red-100 text-red-700 border-red-700',

        1: 'bg-amber-100 text-amber-700 border-amber-700',
        2: 'bg-fuchsia-100 text-fuchsia-700 border-fuchsia-700',
        3: 'bg-teal-100 text-teal-700 border-teal-700',
        4: 'bg-lime-100 text-lime-700 border-lime-700',
        5: 'bg-blue-100 text-blue-700 border-blue-700',
    };

    var colour = colouroptions[0]
    var text = "No Data";

    if (props.type == 1){
        colour = colouroptions[1]
        text = "Client"
    } else if (props.type == 2 && props.name.length > 0){
        colour = colouroptions[3]
        text = "Franchisee"
    }else if (props.type == 2 && props.name.length <= 0){
        if(props.isInt){
            colour = colouroptions[5]
            text = "Totaly247"
        }else{
            colour = colouroptions[2]
            text = "Reseller"
        }
    } else if (props.type == 3){
        colour = colouroptions[4]
        text = "Franchise"
    } else {
        colour = colouroptions[0]
        text = "Incorrect Data"
    }

    return (<div className= {`rounded-md border-solid border flex justify-center px-3 w-40 ${colour}`} > {text} </div>);
}